<template>
  <div class="wrapper">
    <home-video :sendSlience="slienceData"></home-video>
    <!-- <home-header @slience="showMes"></home-header> -->
    <home-content></home-content>
    <dataStatistics></dataStatistics>
    <!-- <div class="live-box">
      <div class="softwareBox">
        培训
        <div class="titleTextEn">
          TRAIN
        </div>
      </div>
      <img class="live_bg"
           src="@/static/img/live_bg.png"
           alt="直播界面背景">
      <div class="live">
        <img class="bar"
             src="@/static/img/bar.png"
             alt="样机">
        <iframe id="iframeLive"
                class="iframe-live"
                scrolling="no"
                allowFullScreen>
        </iframe>
        <div class="enter"
             @click="enterLiver">进入直播间</div>
      </div>
    </div> -->
    <!-- <platform></platform> -->
    <software></software>
    <!-- <foot></foot> -->
  </div>
</template>

<script>
// import HomeHeader from "./header";
import HomeContent from "./content";
import HomeVideo from "./video";
// import GallaryFooter from "../public/footer";
import dataStatistics from "./dataStatistics";
// import platform from "./platform";
import software from "./software";
// import foot from "./foot";
export default {
  name: "Home",
  data () {
    return {
      slienceData: "muted",
      iframeSrc: ''
    };
  },
  components: {
    // HomeHeader,
    HomeContent,
    HomeVideo,
    // GallaryFooter,
    dataStatistics,
    // platform,
    software,
    // foot
  },
  mounted () {
    let iframeDom = document.getElementById('iframeLive')
    iframeDom.src = "//shangzhibo.tv/watch/10170139?player"
    let flag = false
    let flag2 = false
    // flag 防止 多次触发
    // .src刷新iframe页面
    window.onresize = function (e) {
      if (e.target.innerWidth < 1500 && !flag) {
        iframeDom.src = "//shangzhibo.tv/watch/10170139?player"
        flag = true
        flag2 = false
      }
      if (e.target.innerWidth > 1501 && !flag2) {
        iframeDom.src = "//shangzhibo.tv/watch/10170139?player"
        flag2 = true
        flag = false
      }
    }
  },
  methods: {
    showMes (res) {
      console.log(res)
      res == 1 ? (this.slienceData = "muted") : (this.slienceData = "");
    },
    enterLiver () {
      // 进入直播间重置iframe
      let iframeDom = document.getElementById('iframeLive')
      iframeDom.src = "//shangzhibo.tv/watch/10170139?player"
      window.open("https://shangzhibo.tv/watch/10170139")
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  // height: 100vh;
  position: relative;
}

.iframe {
  width: 100%;
  height: 1000px;
}

.live-box,
.live_bg {
  width: 100%;
  position: relative;
  height: 110vh;
}

.live {
  position: absolute;
  top: 174px;
  left: 50%;
  transform: translateX(-50%);
}

.softwareBox {
  width: 100%;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 48px;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  .titleTextEn {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    color: rgba(255, 255, 255, 0.2);
  }
}

.iframe-live {
  width: 1050px;
  height: 590.625px;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.bar {
  width: 100%;
  border-radius: 2px 2px 0 0;
}

@media screen and (max-width: 1500px) {
  .bar {
    width: 900px;
  }

  .iframe-live {
    width: 900px;
    height: 506.25px;
  }

  .live_bg {
    height: 780px;
  }

  .live {
    top: 174px;
  }
}

.enter {
  width: 200px;
  height: 56px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #0083fa;
  border-radius: 28px;
  font-size: 22px;
  font-weight: 500;
  color: #0083fa;
  text-align: center;
  line-height: 56px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -102px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(270deg, #0082fa 0%, #00b2fb 100%);
    box-shadow: 4px 4px 10px 0px rgba(67, 179, 255, 0.3);
    border-radius: 28px;
    color: #ffffff;
    border: none;
    width: 204px;
    height: 60px;
    line-height: 60px;
  }
}
</style>
