<template>
  <div class="statistics">
    <div class="headerTitle">
      <div class="titleText">
        数据统计
        <div class="titleTextEn">
          DATA STATISTICS
        </div>
      </div>
      <div class="numberText">
        {{ time | num }}
      </div>
      <div class="waterText">
        -各平台全局流水突破-
      </div>
    </div>
    <div class="dataBox dataBoxFlex">
      <div class="dataBlock">
        <div class="leftBox"
             style="">
          <span class="BoxText">签约主播</span>
          <div class="minLeft"
               style="width:180px;height:180px">
            <div class="maxBoxText">
              6万+
            </div>
          </div>
        </div>
        <div class="rightBox"
             style="">
          <span class="BoxText">平台经纪人</span>
          <div class="minLeft"
               style="width:180px;height:180px">
            <div class="maxBoxText">
              1万+
            </div>
          </div>
        </div>
        <div class="minileftBox"
             style="">
          <span class="BoxText">线下网红基地</span>

          <div class="minLeft"
               style="width:115px;height:115px">
            <div class="miniBoxText">
              40+
            </div>
          </div>
        </div>
        <div class="minirightBox"
             style="">
          <span class="BoxText">服务主播</span>

          <div class="minLeft"
               style="width:115px;height:115px">
            <div class="miniBoxText">
              10万+
            </div>
          </div>
        </div>
      </div>
      <div class="echatrBox">
        <echart></echart>
      </div>
    </div>
  </div>
</template>

<script>
var ontime;
import echart from "./echart";
export default {
  name: "dataStatistics",
  data () {
    return {
      time: null
    };
  },
  components: {
    echart
  },
  filters: {
    num: (val, fix = 2) => {
      val = val.toFixed(fix); // 保留小数2位
      val = "" + val; // 转换成字符串
      var int = val.slice(0, fix * -1 - 1); // 拿到整数
      // var ext = val.slice(fix * -1 - 1); // 获取到小数
      //每个三位价格逗号
      int = int
        .split("")
        .reverse()
        .join(""); // 翻转整数
      var temp = ""; // 临时变量
      for (var i = 0; i < int.length; i++) {
        temp += int[i];
        if ((i + 1) % 3 == 0 && i != int.length - 1) {
          temp += ","; // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp
        .split("")
        .reverse()
        .join(""); // 加完逗号之后翻转
      return temp; // 返回
    }
  },
  created () {
    this.set_time();
    // ontime = setInterval(this.set_time, 10000);
  },
  mounted () { },
  beforeDestroy () {
    clearInterval(ontime);
  },
  methods: {
    set_time () {
      this.time =
        parseInt(Date.parse(new Date()) / 1000) -
        1100930000 +
        this.random(1, 8);
    },
    random (min, max) {
      if (min >= 0 && max > 0 && max >= min) {
        let gab = max - min + 1;
        return Math.floor(Math.random() * gab + min);
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.statistics {
  height: 870px;
  background-image: url("/@/static/img/home/dataStatisticsBg.png");
  background-position-y: 70%;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  text-align: center;
  width: 1240px;
  margin: 0 auto;
  .statisticsBgImg {
    // position: absolute;
    // top: 130px;
    padding-top: 130px;
    padding-bottom: 80px;
    width: 100%;
  }
  .headerTitle {
    height: 296px;
    text-align: center;
    margin-bottom: 20px;
    .titleText {
      padding-top: 80px;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 48px;
      position: relative;
      .titleTextEn {
        position: absolute;
        position: absolute;
        bottom: 11px;
        left: 0;
        right: 0;
        color: rgba(0, 0, 0, 0.1);
      }
    }
    .numberText {
      font-family: YouSheBiaoTiHei-2;
      font-size: 100px;
      color: #0083fa;
      margin-bottom: 12px;
    }
    .waterText {
      font-family: YouSheBiaoTiHei-2;
      font-size: 28px;
      color: #333;
    }
  }
  .dataBox {
    height: 100%;
    // padding-left: 120px;
    // padding-right: 90px;
    height: 468px;
    .dataBlock {
      position: relative;
      width: 524.3px;
      text-align: left;
      .BoxText {
        text-indent: 8px;
        display: inline-block;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .leftBox {
        transform: rotate(-45deg);
        left: 33px;
        position: absolute;
        top: 30%;
      }
      .rightBox {
        transform: rotate(-45deg);
        right: 42px;
        position: absolute;
        top: 30%;
      }
      .minileftBox {
        width: 115px;
        height: 115px;
        position: absolute;
        top: 41px;
        left: 50%;
        transform: translate(-50%) rotate(-45deg);
      }
      .minirightBox {
        width: 115px;
        height: 115px;
        position: absolute;
        bottom: 41px;
        left: 50%;
        transform: translate(-50%) rotate(-45deg);
      }
      .minLeft {
        width: 180px;
        height: 180px;
        // transform: rotate(0deg);
        border-radius: 10px;
        box-shadow: 0px 2px 36px 2px rgba(23, 128, 224, 0.27);
        background-color: #f6f9fd;
        font-family: YouSheBiaoTiHei-2;
        .maxBoxText {
          transform: rotate(45deg);
          font-size: 70px;
          color: linear-gradient(270deg, #00b2fb 0%, #0082fa 100%);
          background: linear-gradient(270deg, #00b2fb 0%, #0082fa 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          line-height: 180px;
        }
        .miniBoxText {
          transform: rotate(45deg);
          font-size: 43px;
          color: linear-gradient(270deg, #00b2fb 0%, #0082fa 100%);
          background: linear-gradient(270deg, #00b2fb 0%, #0082fa 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          line-height: 115px;
        }
      }
    }
    .echatrBox {
      width: 578px;
    }
  }
  .dataBoxFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1240px;
    margin: 0 auto;
  }
}
</style>
